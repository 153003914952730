<template>
  <div class="addListingPage">
    <div class="addListingPage__banner">
      <img src="../assets/ethicalDashboard.png" height="400"/>
    </div>
    <div class="addListingPage__description">
      <h1>Add Listing</h1>
      <!-- <h1>{{$ml.get('addListingPage.header')}}</h1> -->
      <!-- <p>{{$ml.get('addListingPage.description')}}</p> -->
      <p>Would you like to see your favourite vegan product or venue on the {{ siteConstants.siteName }} market?</p>
      <p>Do you own or run a vegan restaurant, cafe, shop or vegan-friendly hotel?</p>
      <p>You can easily add the item or venue to the listing. Upload your favourite vegan product, place or business on {{ siteConstants.siteName }} and help others discover it!</p>
      <span>
        <!-- <a href="https://ethical.id" target="_blank"><img src="../assets/getEthical.png" height="50"/></a> -->
        <a :href="`${siteConstants.siteUrl}/account`" target="_blank">Add Listing</a>
      </span>
    </div>
  </div>
</template>

<script>
import siteConstants from '@/const.js'

export default {
  name: 'AddListingPage',
  data () {
    return {
      siteConstants
    }
  }
}
</script>

<style scoped lang="stylus">
.addListingPage
  display flex
  background white
  align-items flex-start
  overflow-y auto
  justify-content center
  height 100%
  flex-wrap wrap
  padding 50px 0 0 0
  box-sizing border-box
  &__banner
    margin-top 50px
    display flex
    align-items center
  &__description
    text-align left
    max-width 450px
    display inline-block
    box-sizing border-box
    color #555
    font-size 14px
    padding 70px 0 0 70px
    h1
      font-family: 'Bebas Neue', sans-serif;
      font-size 32px
      font-weight 300
      color black
    span
      font-size 18px
      margin-right 15px
      img
        margin-top 10px

@media screen and (max-width: 668px)
  .addListingPage
    padding 30px 5px
    align-items flex-start
    &__description
      padding 30px 0 0 20px
</style>
